import {
  EuiButton,
  EuiDescriptionList,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiTab,
  EuiTabs,
  EuiDescriptionListProps,
  EuiFlexGroup,
} from "@inscopix/ideas-eui";
import { ShortTaskId } from "components/ShortTaskId/ShortTaskId";
import { TaskStatusBadge } from "components/TaskStatusBadge/TaskStatusBadge";
import { Task, useGetTaskByIdQuery } from "graphql/_Types";
import moment from "moment";
import { useState } from "react";
import { isNonNull } from "utils/isNonNull";
import { isNonNullish } from "utils/isNonNullish";
import { ModalTaskLogsBodyLogs } from "./ModalTaskLogsBodyLogs";
import { ModalTaskLogsResourceUsage } from "./ModalTaskLogsBodyResourceUsage";

type Tab = "LOGS" | "RESOURCE_USAGE";
export interface ModalTaskLogsProps {
  onClose: () => void;
  task: Pick<Task, "id" | "status" | "created">;
}

export const ModalTaskLogs = ({ task, onClose }: ModalTaskLogsProps) => {
  const { data: taskData } = useGetTaskByIdQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      id: task.id,
    },
  });

  const [selectedTab, setSelectedTab] = useState<Tab>("LOGS");

  const taskInfo: NonNullable<EuiDescriptionListProps["listItems"]> = [
    {
      title: "Task ID",
      description: <ShortTaskId taskId={task.id} />,
    },
    {
      title: "Task Status",
      description: <TaskStatusBadge taskStatus={task.status} />,
    },
    {
      title: "Date created",
      description: moment(task.created).format("MMMM Do YYYY, h:mm:ss a"),
    },
  ];

  const fetchedTask = taskData?.task;
  if (isNonNullish(fetchedTask)) {
    if (isNonNull(fetchedTask.toolExitcode)) {
      taskInfo.push({
        title: "Exit code",
        description: fetchedTask.toolExitcode,
      });
    }
    if (typeof fetchedTask?.toolExitcodeDescription === "string") {
      taskInfo.push({
        title: "Exit code description",
        description: fetchedTask.toolExitcodeDescription,
      });
    }
  }

  const tabs: { id: Tab; name: string }[] = [
    {
      id: "LOGS",
      name: "Logs",
    },
    {
      id: "RESOURCE_USAGE",
      name: "Resource Usage",
    },
  ];

  return (
    <EuiModal style={{ height: 800, width: 800 }} onClose={onClose}>
      <EuiModalHeader>
        <EuiFlexGroup direction="column">
          <EuiDescriptionList type="column" listItems={taskInfo} />
          <EuiTabs>
            {tabs.map((tab) => (
              <EuiTab
                key={tab.id}
                id={tab.id}
                isSelected={selectedTab === tab.id}
                onClick={() => setSelectedTab(tab.id)}
              >
                {tab.name}
              </EuiTab>
            ))}
          </EuiTabs>
        </EuiFlexGroup>
      </EuiModalHeader>

      <EuiModalBody>
        {selectedTab === "LOGS" && <ModalTaskLogsBodyLogs task={task} />}
        {selectedTab === "RESOURCE_USAGE" && (
          <ModalTaskLogsResourceUsage task={task} />
        )}
      </EuiModalBody>

      <EuiModalFooter>
        <EuiButton onClick={onClose} fill>
          Close
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};
