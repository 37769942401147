import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiSpacer,
  EuiText,
} from "@inscopix/ideas-eui";
import { UserAvatar } from "components/UserAvatar/UserAvatar";
import { getEnvVar } from "ideas.env";
import { useState } from "react";
import { useUserContext } from "providers/UserProvider/UserProvider";
import { useRoutes } from "hooks/useRoutes";
import { useGetExternalTenants } from "hooks/useGetExternalTenants/useGetExternalTenants";
import { RegionBadge } from "components/RegionBadge/RegionBadge";
import { useRegionsContext } from "providers/RegionsProvider";

const PageDashboardSidebarUser = () => {
  const { routeMap } = useRoutes();
  const currentUser = useUserContext((s) => s.currentUser);

  return (
    <EuiFlexGroup alignItems="center" gutterSize="xs">
      <EuiFlexItem grow={false}>
        <UserAvatar userId={currentUser.id} size="xl" />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup direction="column" gutterSize={"none"}>
          <EuiFlexItem>
            <EuiText title={`${currentUser.firstName} ${currentUser.lastName}`}>
              <h4>
                {currentUser.firstName} {currentUser.lastName}
              </h4>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText
              color="subdued"
              size="xs"
              title={currentUser.email}
              style={{
                maxWidth: "180px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {currentUser.email}
            </EuiText>
          </EuiFlexItem>
          <EuiSpacer size="xs" />
          <EuiFlexItem>
            <EuiLink to={routeMap["SETTINGS"].path}>Profile</EuiLink>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

const PageDashBoardSideBarAbout = () => {
  const { currentRegionKey } = useRegionsContext();
  return (
    <EuiFlexGroup direction="column" gutterSize={"s"}>
      <EuiFlexItem>
        <EuiText>
          <h3>About</h3>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiFlexGroup direction="column" gutterSize={"s"}>
          <EuiFlexItem>
            <EuiText>
              Version: <strong>24.4.6</strong>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiText>
              Region: <strong>{currentRegionKey}</strong>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

const PageDashBoardSideBarSupport = () => {
  const URL_DOCS = getEnvVar("URL_DOCS");
  return (
    <EuiFlexGroup direction="column" gutterSize={"s"}>
      <EuiFlexItem>
        <EuiText>
          <h3>Support</h3>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiText>
          <EuiLink href={`${URL_DOCS}/create-project.html`} target={"_blank"}>
            Getting Started
          </EuiLink>
        </EuiText>
        <EuiText>
          <EuiLink href={`${URL_DOCS}/tools/`} target={"_blank"}>
            Analysis Tools
          </EuiLink>
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiText>
          For any issues or questions that arise, please contact support at{" "}
          <EuiLink href="mailto:support.inscopix@bruker.com">
            support.inscopix@bruker.com
          </EuiLink>
        </EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

const PageDashBoardSideBarOrganizations = () => {
  const { routeMap } = useRoutes();
  const tenants = useUserContext((s) => s.tenants);
  const { externalTenants } = useGetExternalTenants();
  const [showAll, setShowAll] = useState(false);
  const SHOWCOUNT = 5;

  const allTenants = [...tenants, ...externalTenants];

  const tenantsToShow = showAll ? allTenants : allTenants.slice(0, SHOWCOUNT);

  return (
    <div>
      <EuiFlexGroup direction="column" gutterSize="s">
        <EuiFlexItem>
          <EuiText>
            <h3>Organizations</h3>
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem>
          {tenantsToShow.map((tenant) => (
            <EuiFlexGroup
              key={`${
                "region" in tenant ? tenant.region.key : "current"
              }-${String(tenant.id)}`}
              alignItems={"center"}
            >
              <EuiFlexItem>
                <EuiText>
                  {"region" in tenant ? (
                    <EuiLink
                      href={`${tenant.region.url}${
                        routeMap["ORGANIZATION"].dynamicPath({
                          tenantKey: tenant.key as string,
                        }).path
                      }`}
                      target="_blank"
                    >
                      {tenant.name}
                    </EuiLink>
                  ) : (
                    <EuiLink
                      to={
                        routeMap["ORGANIZATION"].dynamicPath({
                          tenantKey: tenant.key,
                        }).path
                      }
                    >
                      {tenant.name}
                    </EuiLink>
                  )}
                </EuiText>
              </EuiFlexItem>
              {"region" in tenant && (
                <EuiFlexItem grow={false}>
                  <RegionBadge regionKey={tenant.region.key} />
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          ))}
          {allTenants.length > SHOWCOUNT && (
            <EuiLink onClick={() => setShowAll((prev) => !prev)}>
              Show {showAll ? "less" : "more"}
            </EuiLink>
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
};

export const PageDashboardSideBar = () => {
  return (
    <EuiFlexGroup direction="column" gutterSize="xl">
      <EuiFlexItem>
        <PageDashboardSidebarUser />
      </EuiFlexItem>
      <EuiFlexItem>
        <PageDashBoardSideBarOrganizations />
      </EuiFlexItem>
      <EuiFlexItem>
        <PageDashBoardSideBarSupport />
      </EuiFlexItem>
      <EuiFlexItem>
        <PageDashBoardSideBarAbout />
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};
